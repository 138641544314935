<div class="content">
  <mat-card>
    <mat-card-header>
      <div class="download-header">
        <h3>Data Export Download</h3>
        <h5 *ngIf="downloadDto !== undefined && downloadDto.exportRequest.fileName !== undefined"> {{downloadDto.exportRequest.fileName}} </h5>
      </div>

    </mat-card-header>
    <mat-card-content>
      <div *ngIf="downloadDto !== undefined">
        <div class="details">
          <span class="detail-line"><h4>Population:</h4> {{downloadDto.population}}</span>
          <span class="detail-line"><h4>Table:</h4> {{downloadDto.exportRequest.tableName}}</span>
          <span class="detail-line"><h4>Main Criteria:</h4> {{downloadDto.exportRequest.exportCriteria}}</span>
          <span class="detail-line"><h4>From:</h4> {{downloadDto.exportRequest.from}}</span>
          <span class="detail-line"><h4>To:</h4> {{downloadDto.exportRequest.to}}</span>
          <span class="detail-line"><h4>Approved:</h4> {{downloadDto.exportRequest.approved}}</span>
          <span class="detail-line"><h4>Exclude Exported:</h4> {{downloadDto.exportRequest.excludeAlreadyExported}}</span>
          <span class="detail-line"><h4 *ngIf="downloadDto.exportRequest.reason">Export Reason:</h4> {{downloadDto.exportRequest.reason}}</span>
        </div>
        <a [href]="downloadDto.uri" mat-raised-button color="accent" download>Download Data</a>
      </div>
      <div *ngIf="downloadDto === undefined" class="inline">
        <mat-progress-spinner mode="indeterminate" diameter="25" color="accent"></mat-progress-spinner>
        <h4>Fetching the Link to your Data</h4>
      </div>
    </mat-card-content>
  </mat-card>
</div>

