import { Component } from '@angular/core';
import {WorkspaceService} from "../../../../../../../services/workspace/workspace.service";
import {WorkspaceDto} from "../../../../../../../models/dto/workspace/workspaceDto";
import {PopulationDto} from "../../../../../../../models/dto/population/populationDto";
import {DataExportService} from "../../../../../../../services/export/data-export.service";
import {ImageDataExportRequestDto} from "../../../../../../../models/dto/export/imageDataExportRequestDto";
import {ILoggingService} from "../../../../../../../services/logging/logging.service.interface";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-population-data-access',
  templateUrl: './population-data-access.component.html',
  styleUrls: ['./population-data-access.component.scss']
})
export class PopulationDataAccessComponent {
  public creatingExport: boolean = false;
  public submitting: boolean = false;

  private workspace?: WorkspaceDto

  public population?: PopulationDto

  public request?: ImageDataExportRequestDto
  constructor(
    private workspaceService: WorkspaceService,
    private exportService: DataExportService,
    private log: ILoggingService
  ) {
    this.workspaceService.workspace.subscribe(res => {
      this.workspace = res;
      this.population = res.settings?.population;

    })
  }

  startExportRequest() {
    this.creatingExport = true;
    this.request = {
      from: new Date(),
      to: new Date(),
      populationId: this.population!.id!,
      name: "TrainingData",
      encounterStructure: false,
      includeAttribution: false
    }
  }


  submit() {
    if (!this.request) return;
    this.submitting = true;
    this.exportService.sendTrainingImageDataExportRequest(this.request).subscribe({
      next: (value: any) => {
        this.log.info("Request submitted. You will receive an email as soon as your download is finished.");
        this.submitting = false;
        this.creatingExport = false;
      }, error: (value: HttpErrorResponse) => {
        this.log.info(`Could not submit request: ${value}`);
        this.submitting = false;
        this.creatingExport = false;
      }
    })
  }

  cancel() {
    this.creatingExport = false;
  }
}
