<button mat-raised-button color=accent (click)="startExportRequest()" *ngIf="!creatingExport">Generate New Export Request for Training Data</button>
<mat-card *ngIf="creatingExport && population">
  <mat-card-title>
    <h2>Creating Export Request for {{population.displayName}}</h2>
  </mat-card-title>
  <mat-card-content *ngIf="request" class="request-card">

    <mat-form-field>
      <mat-label>From</mat-label>
      <input matInput [matDatepicker]="picker" [(ngModel)]="request.from">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
      <mat-label>To</mat-label>
      <input matInput [matDatepicker]="toPicker" [(ngModel)]="request.to">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matIconSuffix [for]="toPicker"></mat-datepicker-toggle>
      <mat-datepicker #toPicker></mat-datepicker>
    </mat-form-field>


    <mat-checkbox class="example-margin" [(ngModel)]="request.encounterStructure">Keep Encounter Structure</mat-checkbox>
    <mat-checkbox class="example-margin" [(ngModel)]="request.includeAttribution">Include Data Which Requires Attribution</mat-checkbox>


    <button mat-raised-button color=accent (click)="submit()">
      <span *ngIf="!submitting">Submit</span>
      <span *ngIf="submitting">
        <mat-progress-spinner diameter="25"></mat-progress-spinner>
      </span>
    </button>
    <button mat-raised-button color="warn" (click)="cancel()">Cancel</button>

  </mat-card-content>
</mat-card>


